.slide-left-in {
    /* display: none; */
    animation: slideLeftIn 0.2s ease-out both;
}

@keyframes slideLeftIn {
    0% {
        transform: translateX(-200%);
        /* display: none; */
    }
    100% {
        transform: translateX(0);
        display: block;
    }
}

.slide-left-out {
    display: block;
    animation: slideLeftOut 0.2s ease-out both;
}

@keyframes slideLeftOut {
    0% {
        transform: translateX(0);
        display: block;
    }
    100% {
        transform: translateX(-200%);
        /* display: none; */
    }
}

.slide-right-in {
    /* display: none; */
    animation: slideRightIn 0.2s ease-out both;
}

@keyframes slideRightIn {
    0% {
        transform: translateX(200%);
        /* display: none; */
    }
    100% {
        transform: translateX(0);
        display: block;
    }
}

.slide-right-out {
    display: block;
    animation: slideRightOut 0.2s ease-out both;
}

@keyframes slideRightOut {
    0% {
        transform: translateX(0);
        display: block;
    }
    100% {
        transform: translateX(200%);
        /* display: none; */
    }
}

.fadeIn {
  animation: fadein 0.5s;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeout 0.5s;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
