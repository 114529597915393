.swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.bg-bullet {
  background-color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}
.swiper-pagination-bullet {
  border-radius: 50%;
  padding: 3px;
  background-color: #999;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #8e54a0;
}

@media screen and (max-width: 700px) {
  .swiper-pagination-bullet {
    padding: 1px;
  }
}
